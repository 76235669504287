import React from 'react';
import classNames from 'classnames/bind';
import { T, withTranslators } from 'lioness';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import {
  CardEmpty,
  Checkbox,
  Tag,
  TextField,
  CardFooter,
  Container,
  Actions,
} from 'src/components/IMUI';
import { retrieveAnswerValue } from 'src/serializers/surveySerializer';
import { getSurveyI18n } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import createQuestion from './createQuestion';
import rightAlignLanguages from './rightAlignLanguages';
import cls from './MultipleChoice.module.css';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
const cx = classNames.bind(cls);

@createQuestion
@connect(pick(['survey', 'surveyAnswers']))
class MultipleChoice extends React.PureComponent {
  static propTypes = {
    onRefresh: PropTypes.func,
    onChange: PropTypes.func,
    changeAnswerOtherChoiceValue: PropTypes.func,
    value: PropTypes.array,
    question: PropTypes.object,
    answer: PropTypes.object,
    isReview: PropTypes.bool,
    readOnly: PropTypes.bool,
    isAnswerMode: PropTypes.bool,
    survey: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array,
    t: PropTypes.func,
  };

  static defaultProps = { value: [] };

  static validate(question, answers = [], otherValue) {
    if (answers && !Array.isArray(answers))
      return 'Invalid answers type, should be array of strings';
    const distinctCount = retrieveAnswerValue(question, answers)?.length ?? 0;
    const otherCount = Number(
      Boolean(question.hasOther && otherValue?.length > 0)
    );
    const answersCount = distinctCount + otherCount;

    if (question.required && !answersCount) {
      return <T>Please select at least one answer</T>;
    }
    if (!question.settings || !question.settings.restrictAnswers) return null;
    if (answersCount > question.settings.maxAnswers) {
      return (
        <T
          message="Please do not select more than {{ maxAnswers }} option"
          messagePlural="Please do not select more than {{ maxAnswers }} options"
          count={question.settings.maxAnswers}
          maxAnswers={question.settings.maxAnswers}
        />
      );
    }
    if (answersCount < question.settings.minAnswers) {
      return (
        <T
          message="Please select at least {{ minAnswers }} option"
          messagePlural="Please select at least {{ minAnswers }} options"
          count={question.settings.minAnswers}
          minAnswers={question.settings.minAnswers}
        />
      );
    }
    return null;
  }

  onChange = (isInputChecked, title) => {
    const { value, question } = this.props;
    const optionsValue = value || [];
    const titleIndex = optionsValue.indexOf(title);
    const newValue =
      optionsValue && optionsValue.length > 0 ? [...optionsValue] : [];
    if (isInputChecked && titleIndex === -1) {
      newValue.push(title);
    } else if (!isInputChecked && titleIndex !== -1) {
      newValue.splice(titleIndex, 1);
    }

    const tagIds = [];

    for (let i = 0; i < question.options.length; i += 1) {
      newValue.forEach((optionName) => {
        if (question.options[i] && question.options[i].title === optionName) {
          !tagIds.includes(question.options[i].tag_id) &&
            tagIds.push(question.options[i].tag_id);
        }
      });
      if (tagIds.length === newValue.length) break;
    }
    this.props.onChange?.(newValue, tagIds);
  };

  onChangeOtherValue = (data) => {
    this.props.changeAnswerOtherChoiceValue?.(this.props.question.id, data);
  };

  getI18nString = (path, altPath) =>
    getSurveyI18n(
      this.props.surveyAnswers.info.id
        ? this.props.surveyAnswers
        : this.props.survey,
      this.props.question,
      path,
      altPath
    );

  getAnswerI18nString = (answer, path, altPath) =>
    getSurveyI18n(this.props.survey, answer, path, altPath);

  getTag = (tagId) =>
    this.props.tagsWithTagGroups.find((st) => st.id === tagId);

  renderOtherChoiceTag = () => {
    if (
      !this.props.isReview ||
      !this.props.question.other_tag_id ||
      !this.props.answer.otherValue
    )
      return null;
    const tag = this.getTag(this.props.question.other_tag_id);
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          <Actions>
            <Icon name="tag" />
            {tag && (
              <Tag
                key={tag.id}
                inline
                label={tag.title}
                tip={(tag.tagGroup || {}).title}
              />
            )}
          </Actions>
        </Container>
      </CardFooter>
    );
  };

  renderOption = (option, optionIndex) => {
    const { readOnly, answer, survey } = this.props;
    const tag = this.getTag(option.tag_id);
    const title = this.getI18nString(option.id, [
      'options',
      optionIndex,
      'title',
    ]);
    const optionsValue = answer.value || [];
    const isChecked = optionsValue.indexOf(option.title) !== -1;
    const rightAlign = rightAlignLanguages.includes(survey.activeLanguage);

    return (
      <Checkbox
        key={option.id}
        name={option.id}
        className={cx('option', {
          'option-readonly': readOnly,
          'option-empty': optionsValue.indexOf(option.title) === -1,
        })}
        onCheck={(ev, isInputChecked) =>
          this.onChange(isInputChecked, option.title)
        }
        label={
          <div
            className={cls.label}
            style={rightAlign ? { paddingRight: '10px' } : {}}
          >
            <span>{title}</span>
            {tag?.title && isChecked && (
              <Tag
                inline
                leftAlign
                label={tag.title}
                tip={(tag.tagGroup || {}).title}
              />
            )}
          </div>
        }
        labelPosition="right"
        checked={isChecked}
        readOnly={readOnly}
        style={rightAlign ? { marginRight: '-14px' } : null}
      />
    );
  };

  render() {
    if (!this.props.question.options?.length)
      return (
        <CardEmpty>
          Please use `Quick add` to add multiple responses to your questions or
          `Add option` to add response choices one by one
        </CardEmpty>
      );
    return (
      <div>
        {this.props.question.options.map(this.renderOption)}
        {this.props.question.hasOther && (
          <TextField
            label={`${this.props.t('Other')}:`}
            multiLine
            name={`text_${0}`}
            value={
              this.props.survey.activeLanguage ===
              this.props.survey.default_language
                ? this.props.answer.otherValue
                : this.getAnswerI18nString(
                    this.props.answer,
                    'other_value',
                    'other_value'
                  ) || this.props.answer.otherValue
            }
            onChange={this.onChangeOtherValue}
            placeholder={
              this.getAnswerI18nString(
                this.props.answer,
                'other_value',
                'other_value'
              )
                ? undefined
                : this.props.t('Please tell us more')
            }
            readOnly={this.props.readOnly}
            noValidation
            flat={!this.props.isAnswerMode}
            border={!this.props.isAnswerMode}
            flatDark={this.props.isAnswerMode}
            wrapperClassName="mt-1"
          />
        )}
        {this.renderOtherChoiceTag()}

        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}

const enhancedComponent = withTranslators(MultipleChoice);
enhancedComponent.validate = MultipleChoice.validate;
export default enhancedComponent;
